/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
  /*	font-size: 11px;*/
  /*   font-size: 16px;*/
  font-family: verdana, helvetica, arial, sans-serif;
  color: #333;
}

html, body {
  font-size: 16px;
}
/*
@media(max-device-width: 576px){html, body{font-size:32px;}}
@media(min-device-width: 576px) and (max-device-width: 768px){html, body{font-size: 25px}}
@media(min-device-width: 768px) and (max-device-width: 992px){html, body{font-size: 19px}}
@media(min-device-width: 992px){html, body{font-size: 16px}}
*/
br {
  font-size: 68.8%;
}

body {
  background: #d0d0d0 url(/assets/images/bg.body.png) repeat-x 0 -2px;
}

a, a.hover {
  text-decoration: none;
  outline: 0;
}

img {
  border: 0;
}

ul {
  list-style-type: none;
}

.highlight {
  color: #ee7c19;
}

h1 {
  font-weight: bold;
  font-size: 23px;
  margin: 0 0 1em;
  padding: 0 0 5px 0;
  color: #555;
  border-bottom: 1px solid #555555;
}

h2 {
  font-weight: bolder;
}

/***********************************************/

div#overall {
  margin: auto;
  width: 920px;
}
@media(max-device-width: 920px){ div#overall { width: 100%; } }

div#top {
  height: 30px;
  padding: 3px 0 0 0;
  /*    background-color: #d0d0d0;*/
}

div#header {
  height: 100px;
}

div#container {
  width:100%;
  display: inline-block;
  /*position: relative;*/
  top: 60px;
  /*background: transparent url(../images/bg.container.gif) repeat-y -50px 0;*/
}

div#container input.submit {
  width: 133px;
}
@media(max-device-width: 576px){div#container input.submit{width: 133px;}}
@media(min-device-width: 576px) and (max-device-width: 768px){div#container input.submit{width: 133px;}}
@media(min-device-width: 768px) and (max-device-width: 992px){div#container input.submit{width: 133px;}}
@media(min-device-width: 992px){div#container input.submit{width: 133px;}}

div#footer {
  /*background: transparent url(../images/bg.footer.gif) no-repeat -50px top;*/
}

/***********************************************/

div#top .instructions, div#top .freecall {
  float: left;
  width: 300px;
}

div#top .instructions {
  position: relative;
  padding: 1px 0 2px 0;
  color: #999;
  z-index: 1000;
}

div#top .instructions a#toggleInfo, div#top .instructions a:hover#toggleInfo {
  display: block;
  height: 16px;
  padding: 1px 0 0 20px;
  background: url(/assets/images/icon.info.gif) no-repeat 0 -1px;
}

div#top .instructions a:hover#toggleInfo, div#top .instructions a.open, div#top .instructions a.open:hover {
  background-position: 0 -17px !important;
}

div#top div#instructions {
  position: absolute;
  top: 21px;
  left: 19px;
  width: 873px;
  border: 1px solid #aaa;
  _filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/calculator/bg.instructions.png', sizingMethod=scale);
  z-index: 1000;
}

html > body div#top div#instructions {
  background: transparent url(/assets/images/calculator/bg.instructions.png) no-repeat;
}

.wrappall { background: url(/assets/images/wrappall_bg.jpg) repeat-x center bottom; }

div#top div#instructions div#content {
  position: relative;
  width: 873px;
  height: 780px;
}

div#top div#instructions div#content div {
  position: absolute;
  padding: 6px 10px;
  font-size: 68.8%;
  color: #eee;
  line-height: 1.5;
}

div#top div#instructions div#content div h1, div#top div#instructions div#content div h2 {
  font-size: 75.0%;
  color: #eee;
}

div#top div#instructions div#content div#info-1 {
  top: 0;
  right: 0;
  width: 853px;
  color: #333;
  border-bottom: 1px solid #ccc;
}

div#top div#instructions div#content div#info-1 a {
  float: right;
}

div#info-2 {
  top: 175px;
  right: 10px;
  width: 610px;
}

div#info-3 {
  top: 270px;
  right: 10px;
  width: 610px;
}

div#info-4 {
  top: 540px;
  right: 160px;
  width: 235px;
}

div#info-5 {
  top: 340px;
  left: 10px;
  width: 190px;
}

div#info-6 {
  top: 535px;
  left: 215px;
  width: 210px;
}

div#top div#instructions div#content div#info-7 {
  bottom: 0;
  left: 0;
  width: 853px;
  color: #333;
  border-top: 1px solid #ccc;
  /*background: #eee url(../images/bg.grey.fade.gif) repeat-x;*/
}

div#top div#instructions div#content div#info-1 h1, div#top div#instructions div#content div#info-7 h2 {
  color: #333;
}

div#top div#instructions a#close, div#top div#instructions a:hover#close {
  display: block;
  border-top: 1px solid #aaa;
  padding: 4px 0;
  text-align: center;
  font-size: 62.5%;
  text-transform: uppercase;
}

div#top div#instructions a:hover#close {
  color: #666;
}

div#top .breadcrumb a.current, div#top .breadcrumb a:hover strong {
  text-decoration: underline;
}

div#top .freecall {
  height: 22px;
  padding-top: 0;
  color: #666;
  font-weight: bold;
  font-size: 75%;
}

@media(min-width: 992px){div#top .freecall {padding-top: 3px;}}

div#top .freecall strong {
  font-weight: normal;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 100%;
}

/***********************************************/

div#header img {
  margin: 30px 0 0 5px;
}

@media(max-device-width: 576px){div#header img {  margin: 0 0 0 0;}}


/***********************************************/

div.button, nav.navbar {
  float: left;
}

a.navbar-brand {
  width: 224px;
  height: 41px;
  padding: 9px 0 0 18px;
}

/*@media(max-device-width: 576px){div.button{padding-top:0px}}
@media(min-device-width: 576px) and (max-device-width: 768px){div.button{padding-top:5px}}
@media(min-device-width: 768px) and (max-device-width: 992px){div.button{padding-top:7px}}
*/
nav.navbar {
  width: 910px;
  padding-top: 0;
  border-top-width: 0;
  /*	background: #fefdf4 url(../images/calculator/bg.navi.gif) no-repeat;*/
}

@media(max-device-width: 767px){ nav.navbar { width: 100% } }

.nav>li {
  padding-right:1px;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

nav.navbar a.link, nav.navbar a.link:hover {
  display: block;
  float: left;
  height: 50px;
  width: 48px;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: help;
}

nav.navbar a.navbar-brand {
  width: 210px;
}

.navbar {
  z-index: 9999;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

nav.navbar a.link {
  background-image: url(/assets/images/calculator/bg.links.png);
  background-color: #f2f2f2;
  margin-left: 1px;
}

nav.navbar a.link-1 {
  background-position: 0 0;
}

nav.navbar a.link-2 {
  background-position: -314px 0;
}

nav.navbar a.link-3 {
  background-position: -372px 0;
}

nav.navbar a.link-4 {
  background-position: -430px 0;
}

nav.navbar a.link-5 {
  background-position: -488px 0;
}

nav.navbar a.link-6 {
  background-position: -546px 0;
}

nav.navbar a.link-7 {
  background-position: -604px 0;
}

nav.navbar a.link-8 {
  background-position: -662px 0;
}

nav.navbar a.link-9 {
  background-position: -720px 0;
}

nav.navbar a.link-10 {
  background-position: -56px 0;
}

nav.navbar a.link-11 {
  background-position: -114px 0;
}

nav.navbar a.link-12 {
  background-position: -172px 0;
}

nav.navbar a.link-finish, nav.navbar a:hover.link-finish {
  width: 74px;
  _width: 70px;
  background-position: -232px 0;
}

nav.navbar a.link-1-done {
  background-position: 0 -100px;
}

nav.navbar a.link-2-done {
  background-position: -316px -100px;
}

nav.navbar a.link-3-done {
  background-position: -374px -100px;
}

nav.navbar a.link-4-done {
  background-position: -432px -100px;
}

nav.navbar a.link-5-done {
  background-position: -490px -100px;
}

nav.navbar a.link-6-done {
  background-position: -548px -100px;
}

nav.navbar a.link-7-done {
  background-position: -606px -100px;
}

nav.navbar a.link-8-done {
  background-position: -664px -100px;
}

nav.navbar a.link-9-done {
  background-position: -722px -100px;
}

nav.navbar a.link-10-done {
  background-position: -58px -100px;
}

nav.navbar a.link-11-done {
  background-position: -116px -100px;
}

nav.navbar a.link-12-done {
  background-position: -174px -100px;
}

nav.navbar a.link-finish-done, nav.navbar a:hover.link-finish-done {
  width: 74px;
  _width: 70px;
  background-position: -232px -100px;
}

nav.navbar a.link-1-done-current {
  background-position: 0 -50px;
}

nav.navbar a.link-2-done-current {
  background-position: -316px -50px;
}

nav.navbar a.link-3-done-current {
  background-position: -374px -50px;
}

nav.navbar a.link-4-done-current {
  background-position: -432px -50px;
}

nav.navbar a.link-5-done-current {
  background-position: -490px -50px;
}

nav.navbar a.link-6-done-current {
  background-position: -548px -50px;
}

nav.navbar a.link-7-done-current {
  background-position: -606px -50px;
}

nav.navbar a.link-8-done-current {
  background-position: -664px -50px;
}

nav.navbar a.link-9-done-current {
  background-position: -722px -50px;
}

nav.navbar a.link-10-done-current {
  background-position: -58px -50px;
}

nav.navbar a.link-11-done-current {
  background-position: -116px -50px;
}

nav.navbar a.link-12-done-current {
  background-position: -174px -50px;
}

nav.navbar a.link-finish-done-current, nav.navbar a:hover.link-finish-current {
  width: 74px;
  _width: 70px;
  background-position: -232px -50px;
}
/*
nav.navbar a.link-1 {
	background-image: url(../images/calculator/bg.link.1.gif);
}

nav.navbar a.link-2 {
	background-image: url(../images/calculator/bg.link.2.gif);
}

nav.navbar a.link-3 {
	background-image: url(../images/calculator/bg.link.3.gif);
}

nav.navbar a.link-4 {
	background-image: url(../images/calculator/bg.link.4.gif);
}

nav.navbar a.link-5 {
	background-image: url(../images/calculator/bg.link.5.gif);
}

nav.navbar a.link-6 {
	background-image: url(../images/calculator/bg.link.6.gif);
}

nav.navbar a.link-7 {
	background-image: url(../images/calculator/bg.link.7.gif);
}

nav.navbar a.link-8 {
	background-image: url(../images/calculator/bg.link.8.gif);
}

nav.navbar a.link-9 {
	background-image: url(../images/calculator/bg.link.9.gif);
}

nav.navbar a.link-10 {
	background-image: url(../images/calculator/bg.link.10.gif);
}

nav.navbar a.link-11 {
	background-image: url(../images/calculator/bg.link.11.gif);
}

nav.navbar a.link-12 {
	background-image: url(../images/calculator/bg.link.12.gif);
}

nav.navbar a.link-13, nav.navbar a:hover.link-13 {
	width: 74px;
	_width: 70px;
	background-image: url(../images/calculator/bg.link.13.gif);
}

nav.navbar a.done {
	background-position: 0 -100px;
}

nav.navbar a.current, nav.navbar a:hover.current {
	background-position: 0 -50px;
}*/

div.bottom {
  padding: 5px 0 5px 259px;
}

/***********************************************/

div#column-1, div#column-2 {
  padding: 2px 55px 10px 0;
}

div#column-1 {
  min-height: 420px;
}

/***********************************************/

div#column-1 input.submit {
  float: right;
  margin-right: 24px;
  cursor: pointer;
}

div#column-1 div.box {
  float: left;
  position: relative;
  padding: 10px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  _filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/calculator/bg.orange.fade.png', sizingMethod=scale);
  margin-bottom: 20px;
}

html > body div#column-1 div.box {
  background: #fff;
}

div#column-1 div.over {
  _filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/calculator/bg.orange.fade.hover', sizingMethod=scale);
}

html > body div#column-1 div.over {
  background: #f4f4f4;
}

div#column-1 div.big {
  width: 573px;
  min-height: 150px;
  _height: 150px;
}

div#column-1 div.medium, div#column-1 div.small {
  width: 265px;
  min-height: 225px;
  _height: 225px;
  margin: 0 20px 20px 0;
}

div#column-1 div.small {
  width: 163px;
  min-height: 150px;
  _height: 150px;
}

div#column-1 div.hint {
  float: left;
  width: 367px;
  padding: 0 !important;
  min-height: 20px;
  _height: 20px;
  cursor: auto;
}

div#column-1 div.hint div {
  float: none !important;
  padding: 10px !important;
  text-align: center;
}

div#column-1 div.hint-big {
  float: left;
  width: 435px;
  padding: 0 !important;
  min-height: 20px;
  cursor: auto;
}

div#column-1 div.hint-big div {
  float: none !important;
  padding: 10px !important;
  text-align: center;
}

div#column-1 div.hint-large {
  float: left;
  width: 593px;
  padding: 0 !important;
  min-height: 20px;
  cursor: auto;
}

div#column-1 div.hint-large div {
  float: none !important;
  padding: 10px !important;
  text-align: center;
}

div#column-1 div.hint-large div h2 {
  margin: 0;
  padding: 0 !important;
}

div#column-1 div.hint-large div strong {
  font-weight: normal;
  line-height: 1.5;
}

div#column-1 div.box p.checkbox {
  position: absolute;
  right: 20px;
  top: 45%;
  width: 20px;
  height: 20px;
  padding-right: 20px;
  background: transparent url(/assets/images/calculator/bg.checkbox.gif) no-repeat right center;
}


div#column-1 div.active p.checkbox {
  background-image: url(/assets/images/calculator/bg.checkbox.active.gif);
}

div#column-1 div.box img.image, div#column-1 div.box div {
  float: left;
  padding: 10px;
}

div#column-1 div.medium div {
  clear: left;
  width: 220px;
}



div#column-1 div.box div {
  padding-right: 30px;
}

div#column-1 div.box div h2 {
  padding: 5px 0;
  font-size: 87.5%;
}

img.lt, img.lb, img.rt, img.rb {
  position: absolute;
  padding: 0;
  width: 3px;
  height: 3px;
}

img.lt {
  left: -1px;
  top: -1px;
}

img.lb {
  left: -1px;
  bottom: -1px;
}

img.rt {
  right: -1px;
  top: -1px;
}

img.rb {
  right: -1px;
  bottom: -1px;
}

div#column-1 div.box input.radio {
  position: absolute;
  top: 50%;
  right: 20px;
}

div#column-1 div.more {
  width: 563px;
  padding: 15px;
  cursor: auto;
}

div#column-1 div.more p.center {
  text-align: center;
}

div.list-1, div.list-2 {
  /*	font-size: 81.3%;*/
}

div.list-1 strong, div.list-2 strong {
  font-size: 127.3%;
  color: #4c7a1d;
}

@media(max-device-width: 380px){div.list-1 strong, div.list-2 strong {font-size: 115%;}}


div.list-2 strong {
  color: #d37a0a;
}

.info {
  padding: 10px 0;
  line-height: 1.3;
  font-size: 75.0%;
}

.info strong {
  font-size: 120%;
}


small {
  font-size: 56.3%;
}

div#column-1 input.smallInput {
  width: 20px;
  padding:2px;
  text-align: center;
  border:1px solid #aaa
}

textarea, input, select {
  padding: 5px;
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
  height: 160px;
}

label {
  font-weight: normal;
}

input.error {
  border-color: #ee7c19;
}

.info label, .info input.selector {
  float: left;
  margin: 2px 5px 0 0;
}

.info label {
  margin-top: -3px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 68.8%;
}

@media(max-device-width: 380px){.info label {font-size: 100%;}}


.info label strong {
  font-size: 109.1%;
}

div.personal {
  padding-left: 20px;
}

div.personal p {
  float: left;
  padding: 0 5px 0 0;
}

p.personal-1 {
  /*	width: 120px;*/
  text-align: right;
  font-size: 68.8%
}

@media(max-device-width: 768px){p.personal-1 {
  /*   width: 100%;   */
}}

p.personal-2 {
  /*	width: 340px;*/
  font-size: 68.8%
}

p.personal-1 strong, p.personal-2 label {
  font-size: 109.1%;
  font-weight: bold;
}

p.personal-1 input {
  margin: 0;
}

p.personal-2 input, p.personal-2 select {
  float: left;
  margin: -4px 7px 0 0;
}

p.personal-2 label {
  float: left;
  margin-top: -2px;
  cursor: pointer;
}

p.personal-2 select {
  width: 60px;
  padding: 3px 0 3px 5px;
}

p.personal-contact {
  text-align: left;
  width: auto;
}

/***********************************************/

div#column-2 h3.line {
  height: 1px;
  font-size: 1px;
  background: url(/assets/images/calculator/bg.line.gif) no-repeat center;
}

div#column-2 ul.list li {
  padding: 2px 10px 4px 10px;
  border-left: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  border-bottom: 1px solid #c0c0c0;
}

div#column-2 ul.list li.center {
  background: none;
  padding: 2px 10px 0 10px;
  font-size: 68.8%;
}

div#column-2 ul.list li.center strong {
  font-size: 100%;
}



div#column-2 ul.list li a, div#column-2 ul.list li a:hover, nav.navbar a.type-link, nav.navbar a.type-link:hover {
  display: block;
  margin: 2px 0;
  padding-right: 15px;
  background: transparent url(/assets/images/icon-arrow-green.png) no-repeat right center;
}

div#column-2 ul.list li a:hover, nav.navbar a.type-link:hover {
  background-image: url(/assets/images/icon-arrow-orange.png);
}

nav.navbar a.type-link, nav.navbar a.del-link {
  background-color: #60953d;
  width: 100%;
  padding-left: 10px;
  margin: 0;
}

div#column-2 img {
  float: right;
  margin-right: 10px;
}

/***********************************************/

span.active {
  color: green;
}

.card .card-header {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding-bottom: 0;
  padding-top: 5px;
}

.active  .card-header {
  background-color: green;
}

.active  .card-header .card-title {
  color: white;
}

span.error {
  color: red;
}

.error > .card-header {
  color: white;
  background-color: red;
}

img.choiceImage {
  height: 121px;
}

@media(max-device-width: 576px){img.choiceImage {height: 80px;}}
@media(max-device-width: 576px){img.priceImage {width: 240px;}}


/**********************************************/

div#footer div {
  height: 40px;
  padding: 80px 30px 0 25px;
  font-size: 62.5%;
  color: #82b154;
  text-align: right;
}

div#footer div span {
  color: #ee7c19;
  font-size: 62.5%;
}

div#footer .navi {
  float: left;
  padding: 3px 0;
}

div#footer .navi li {
  float: left;
  padding: 0 1px 0 3px;
  color: #888;
}

div#footer a.link {
  font-size: 62.5%;
  color: #999;
}

div#footer a:hover.link, div#footer a.current, div#footer a:hover.current {
  color: #333;
}

/**********************************************/
/* bootstrap anpassungen */
/**********************************************/

.card-title {
  font-size: 100%;
}

.card-body {
  padding: 10px;
}

.card-body .description {
  font-size: 68.8%;
  padding-top: 10px;
}

.card-body .description b{
  font-size: 100%;
}

/*.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-right: 5px;
  padding-left: 5px;
}*/

a.type-link strong{
  font-size: 68.8%;
}
a.type-link div{
  font-size: 68.8%;
}

div.priceview p {
  font-size: 68.8%;
}
